<template>
  <div class="container">
    <div class="text-end p-3">
      <c-primary-button to="/admin/marcos-legales-generales/crear">
        Crear registro
      </c-primary-button>
    </div>
    <c-card title="Marco legal general">
      <template v-slot:header-right>
        <div class="input-group mb-3 header-search">
          <input
            v-model="searchValue"
            type="text"
            class="form-control"
            @keyup="search"
          />
          <span class="input-group-text">
            <i class="fa-solid fa-magnifying-glass"></i>
          </span>
        </div>
      </template>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Descripción</th>
            <th>Documento</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="marco in marcosLegalesGenerales" :key="marco.id">
            <td>{{ marco.name }}</td>
            <td>{{ marco.description }}</td>
            <td>
              <div class="mb-3">
                <div v-if="marco.document">
                  <a
                    :href="marco.document"
                    target="_blank"
                    class="btn btn-secondary btn-sm"
                  >
                    <i class="fa-solid fa-eye"></i>
                  </a>
                  <label :for="`document-${marco.id}`">
                    <span class="btn btn-secondary btn-sm ms-2 d-inline-block"
                      ><i class="fa-solid fa-pen"></i
                    ></span>
                  </label>
                </div>
                <label v-else :for="`document-${marco.id}`">
                  <span class="btn btn-secondary btn-sm d-inline-block"
                    >Agregar</span
                  >
                </label>
                <input
                  @change="uploadDocument($event, marco.id)"
                  class="form-control form-control-sm visually-hidden"
                  type="file"
                  :id="`document-${marco.id}`"
                />
              </div>
            </td>
            <td class="text-nowrap">
              <add-m-l-g-to-profile :MLG="marco" :allProfiles="profiles" />
              <router-link
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                :to="`/admin/marcos-legales-generales/${marco.id}/versiones`"
                title="Versiones"
                ><i class="fa-solid fa-list-ol"></i
              ></router-link>
              <router-link
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                :to="`/admin/marcos-legales-generales/${marco.id}/editar`"
                title="Editar"
                ><i class="fa-solid fa-pen"></i
              ></router-link>
              <a
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                href="javascript:void(0)"
                @click="deleteMarcoLegalGeneral(marco.id)"
                title="Eliminar"
                ><i class="fa-solid fa-trash-can"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import AddMLGToProfile from "@/components/MarcoLegalGeneral/AddMLGToProfile.vue";
import { all as allProfiles } from "@/services/profiles";
import {
  all,
  remove,
  uploadDocument,
} from "@/services/marcos_legales_generales";
import { showNotification } from "@/services/notification";

export default {
  components: {
    CCard,
    CPrimaryButton,
    AddMLGToProfile,
  },
  data() {
    return {
      marcosLegalesGenerales: [],
      timer: null,
      searchValue: "",
      profiles: [],
    };
  },
  mounted() {
    this.getProfiles();
  },
  methods: {
    getProfiles() {
      allProfiles().then((response) => {
        this.profiles = response.data;
        this.getMarcosLegalesGenerales();
      });
    },

    getMarcosLegalesGenerales(params = {}) {
      all(params).then((response) => {
        this.marcosLegalesGenerales = response.data;
      });
    },
    deleteMarcoLegalGeneral(id) {
      const removeRecord = confirm("¿Estás seguro de eliminar el registro?");
      if (!removeRecord) {
        return;
      }
      remove(id).then(() => {
        showNotification(204, "success", "El registro fue eliminado.");
        this.getMarcosLegalesGenerales();
      });
    },
    uploadDocument(event, id) {
      const document = event.target.files[0];
      let formData = new FormData();
      formData.append("document", document);
      uploadDocument(id, formData).then(() => {
        showNotification(
          204,
          "success",
          "El archivo fue guardado exitosamente."
        );
        this.getMarcosLegalesGenerales();
      });
    },
    search() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getMarcosLegalesGenerales({ name: this.searchValue });
      }, 800);
    },
  },
};
</script>
