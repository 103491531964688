<template>
  <div class="dropdown d-inline-block" title="Agregar a perfil">
    <button
      class="dropdown-toggle btn btn-secondary btn-sm ms-2 d-inline-block"
      :id="`dropdown-${MLG.id}`"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="fa-solid fa-plus"></i>
    </button>
    <ul class="dropdown-menu border-0">
      <li v-for="profile in profiles" :key="profile.id">
        <a
          @click.prevent="
            profile.addedToMLG
              ? removeFromProfile(profile.id)
              : addMLGToProfile(profile.id)
          "
          href="javascript:void(0)"
          class="text-decoration-none dropdown-item"
          >{{ profile.addedToMLG ? "Eliminar de " : "Agregar a " }}
          {{ profile.name }}</a
        >
      </li>
    </ul>
  </div>
</template>
<script>
import {
  addMarcoLegalGeneral,
  removeMarcoLegalGeneral,
} from "@/services/profiles";
import { showNotification } from "@/services/notification";
export default {
  props: {
    MLG: {
      required: true,
      default: {},
    },
    allProfiles: {
      required: true,
      default: [],
    },
  },
  data() {
    return {
      profiles: this.allProfiles.map((profile) => ({ ...profile })),
    };
  },
  mounted() {
    this.validateProfilesAlreadyRelated();
  },
  methods: {
    validateProfilesAlreadyRelated() {
      // extraer primer MLE de cada versión del MLG
      const MLEs = [];
      this.MLG.versions.forEach((version) => {
        if (version.marcos_legales_especificos.length) {
          MLEs.push(version.marcos_legales_especificos[0]);
        }
      });

      // buscar si el perfil existe en el MLE
      this.profiles.forEach((profile) => {
        MLEs.forEach((mle) => {
          const found = mle.profiles.find(
            (mleProfile) => mleProfile.id === profile.id
          );
          found ? (profile.addedToMLG = true) : (profile.addedToMLG = false);
        });
      });
    },

    addMLGToProfile(profileId) {
      addMarcoLegalGeneral(profileId, this.MLG.id).then(() => {
        const profile = this.profiles.find(
          (profile) => profile.id === profileId
        );
        profile.addedToMLG = true;
        showNotification(
          200,
          "success",
          "Se agregó el marco legal general al perfil."
        );
      });
    },

    removeFromProfile(profileId) {
      removeMarcoLegalGeneral(profileId, this.MLG.id).then(() => {
        const profile = this.profiles.find(
          (profile) => profile.id === profileId
        );
        profile.addedToMLG = false;
        showNotification(
          200,
          "success",
          "Se eliminó el marco legal general al perfil."
        );
      });
    },
  },
};
</script>
